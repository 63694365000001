import React, { useEffect, useState } from "react";
import './CallVotingList.css';

const CallVotingList = (props) => {

    const {
        rowId,
        nameAnswer,
        addAnswerToArray,
        removeAnswerFromArray,
        questionColumns,
        isBulletinVoted,
        indexRows,
        selectImage
    } = props;

    const columnId = questionColumns[0].id;

    const [isCheckboxChecked, setCheckboxChecked] = useState(false);
    const [isAnswerString, setAnswerString] = useState(true);

    useEffect(() => {
        if (nameAnswer.includes('https://') || nameAnswer.includes('http://')) {
            setAnswerString(false)
        } else {
            setAnswerString(true)
        }
    }, [nameAnswer])

    useEffect(() => {
        if (isBulletinVoted) {
            setCheckboxChecked(false);
        }
    }, [isBulletinVoted])

    function onCheckboxClick() {
        if (isCheckboxChecked) {
            setCheckboxChecked(false);
            removeAnswerFromArray(rowId);
        } else {
            setCheckboxChecked(true);
            addAnswerToArray(rowId, columnId);
        }
    }

    return (
        <div className='www'>
            {isBulletinVoted ? (
                <div className={isAnswerString ? 'call-voting-list__blue-square-container' : 'call-voting-list__blue-square-container-type-img'}>
                    <div className="call-voting-list__blue-square" />
                    {isAnswerString ? (
                        <>
                            <span className='call-voting-list__text'>{nameAnswer}</span>
                        </>
                    ) : (
                        <>
                            <div>
                                <p className="call-voting-list__name-answer-index-img">№{indexRows + 1}</p>
                                <img className="call-voting-list__name-answer-img" src={nameAnswer} alt={'картинка'}/>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div className={isAnswerString ? "checkbox__main-container" : "checkbox__main-container-type-img"}>
                    <label className='checkbox_container'>
                        <input
                            type="checkbox"
                            name={rowId}
                            checked={isCheckboxChecked}
                            onChange={onCheckboxClick}
                        />
                        <span className='checkmark-row' />
                    </label>
                    {isAnswerString ? (
                        <>
                            <span className='call-voting-list__name-answer'>{nameAnswer}</span>
                        </>
                     ) : (
                        <>
                            <div>
                                <p className="call-voting-list__name-answer-index-img">№{indexRows + 1}</p>
                                <img onClick={() => selectImage(indexRows, nameAnswer)} title={'Что бы увеличить нажмите на картинку'} className="call-voting-list__name-answer-img" src={nameAnswer} alt={'картинка'}/>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}
export default CallVotingList;
